import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider, Navigate,
} from "react-router-dom";
import Login from "./pages/Login/Login";
import AuthProvider from "./hooks/AuthProvider";
import Dashboard from "./pages/Dashboard/Dashboard";
import Menu from "./pages/Menu/Menu";
import Register from "./pages/Register/Register";
import Invite from "./pages/Invite/Invite";
import Pdf from "./pages/Pdf/Pdf";
import PasswordRemind from "./pages/PasswordRemind/PasswordRemind";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import "./instrument";
import Gallery from "./pages/Gallery/Gallery";

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
    {
        path: "/login",
        element: <AuthProvider>
            <Login/>
        </AuthProvider>,
    },
    {
        path: "/dashboard",
        element: <AuthProvider>
            <Dashboard/>
        </AuthProvider>,
    },
    {
        path: "/menu/:id",
        element: <AuthProvider>
            <Menu/>
        </AuthProvider>,
    },
    {
        path: "/register/:token/:email",
        element: <AuthProvider>
            <Register/>
        </AuthProvider>,
    },
    {
        path: "/invite",
        element: <AuthProvider>
            <Invite/>
        </AuthProvider>,
    },
    {
        path: "/pdf",
        element: <AuthProvider>
            <Pdf/>
        </AuthProvider>,
    },
    {
        path: "/forgot-password",
        element: <AuthProvider>
            <PasswordRemind />
        </AuthProvider>,
    },
    {
        path: "/reset-password/:token",
        element: <AuthProvider>
            <ResetPassword />
        </AuthProvider>,
    },
    {
        path: "/gallery",
        element: <AuthProvider>
            <Gallery />
        </AuthProvider>,
    },
    {
        path: "/",
        element: <AuthProvider>
            <Navigate to="/login" />
        </AuthProvider>,
    },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
