import "../../layout.css";
import "./photosGallery.css";
import close from "../../images/close.svg";
import trash from "../../images/trash.svg";

function PhotosGallery({loading, visible, setModal, photos, deleteImage}) {

    return (
        <div className={visible ? "photos-gallery" : "none"}>
            <img src={close} alt={"close"} width={30} height={30} onClick={() => {setModal(false)}} className={"photos-gallery-close-button"} />
            {loading ? (
                <div className="spinner"></div>
            ) : (<>
                {photos.length > 0 ? (<h5 className={"photos-gallery-header"}>Aktualne zdjęcia w bibliotece Darboven:</h5>) : <h5 className={"empty-photos-gallery-header"}>Dodaj zdjęcia do biblioteki Darboven.</h5>}
                <div className={"photos-gallery-content"}>
                    {photos.reverse().map((photo, index) => {
                        return (
                            <div key={index} className={"photos-gallery-image-container"}>
                                <img src={trash} alt={"remove"} width={30} height={30} onClick={(event) => {
                                    deleteImage(event, photo.name);
                                }} className={"photos-gallery-remove-button"} />
                                <img src={`${process.env.REACT_APP_SERVER_URL}${photo?.path.slice(1)}`} alt={"gallery-photo"} className={"photos-gallery-image"} />
                            </div>
                        );
                    })}
                </div>
                </>
            )}
        </div>
    );
}

export default PhotosGallery;

