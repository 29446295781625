import "../../layout.css";
import "./gallery.css";
import Header from "../../components/Header/Header";
import ActionButton from "../../components/ActionButton/ActionButton";
import React, {useCallback, useEffect, useState} from "react";
import "easymde/dist/easymde.min.css";
import {useAuth} from "../../hooks/AuthProvider";
import {uploadDarbovenImage, getDarbovenGallery, deleteDarbovenImage} from "../../services/menu";
import UploadImage from "../../components/UploadImage/UploadImage";
import AlertModal from "../../components/AlertModal/AlertModal";
import PhotosGallery from "../../components/PhotosGallery/PhotosGallery";
import Navbar from "../../components/Navbar/Navbar";

function Gallery() {
    const [modal, setModal] = useState(false);
    const [userGalleryPhotos, setUserGalleryPhotos] = useState([]);
    const auth = useAuth();
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);
    const [galleryLoading, setGalleryLoading] = useState(false);

    function upload(event) {
        setUploadButtonDisabled(true);
        uploadDarbovenImage(auth.token, event.target.files[0])
            .then(r => {
                setUploadButtonDisabled(false);

                setShowAlertModal(true);
                setAlertModalMessage("Dodano obrazek");
                setAlertModalSuccess(true);
            })
            .catch(e => {
                setUploadButtonDisabled(false);

                setShowAlertModal(true);
                setAlertModalMessage("Nie udało się dodać obrazka");
                setAlertModalSuccess(false);
            })
    }

    function deleteImage(event, name) {
        deleteDarbovenImage(auth.token, name)
            .then(r => {
                setShowAlertModal(true);
                setAlertModalMessage("Usunięto obrazek");
                setAlertModalSuccess(true);
                event.target.parentElement.remove()
            })
            .catch(e => {
                setShowAlertModal(true);
                setAlertModalMessage("Nie udało się usunąć obrazka");
                setAlertModalSuccess(false);
            })
    }

    const handleLoadingGallery = (e) => {
        setGalleryLoading(true);
        setModal(true);
        getDarbovenGallery(auth.token)
            .then(r => {
                setUserGalleryPhotos(r?.data?.items);
                setGalleryLoading(false);
            })
            .catch(e => {
                setGalleryLoading(false);
            })
    }

    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalMessage, setAlertModalMessage] = useState("");
    const [alertModalSuccess, setAlertModalSuccess] = useState(true);

    if (auth.user)
        return (
            <main>
                <Navbar date={"01.01.2024"} showDate={false} />
                {auth.roles?.includes("ROLE_GALLERY") || auth.roles?.includes("ROLE_ADMIN") ? (
                    <>
                        <AlertModal visible={showAlertModal} text={alertModalMessage} success={alertModalSuccess} setModal={setShowAlertModal}/>
                        <div className={"flex justify-center"} style={{paddingTop: "120px"}}>
                            <div className={"col-4 ml20 flex-column gap-40"}>
                                <div className={"dashboard-tile dashboard-border"}>
                                    <Header header={"Biblioteka zdjęć"} text={"Gotowe zdjęcia kaw i napojów od Darboven."} whiteText={false} />
                                    <ActionButton onClick={handleLoadingGallery} text={"Biblioteka"} height={"48px"} whiteBorder={false} transparent={true} />
                                    <UploadImage text={"Prześlij własne zdjęcie"} onChange={(event) => {upload(event)}} disabled={uploadButtonDisabled} />
                                </div>
                            </div>
                        </div>
                        <PhotosGallery loading={galleryLoading} visible={modal} setModal={setModal} photos={userGalleryPhotos} deleteImage={deleteImage} />
                    </>
                ) : (
                    <>
                        Nieuprawniony użytkownik
                    </>
                )}
            </main>
        );
    else
        return (
            <>
                Niezalogowany
            </>
        );
}

export default Gallery;